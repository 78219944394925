import { useMemo, useRef, useState } from "react";

import { ConnectorOption, useFilterConnectors } from "@/features/connectors";
import { useConnectionsByIntegrationId } from "@/features/connectors/lib/useConnectionsByIntegrationId";
import { useControllableState } from "@/hooks/useControllableState";
import useUpdateEffect from "@/hooks/useUpdateEffect";
import { Ability, isCustomConnector } from "@/integrations";

export type UseConnectorsProps = {
  options: ConnectorOption[];
  abilityFilter?: Ability;
  sortOption?: "alphabetical" | "popular";
  onAbilityFilterChange?: (ability: Ability | null) => void;
  onOptionsFiltered?: (
    options: ConnectorOption[],
    filters: {
      abilityFilter: Ability | null;
      searchText: string;
    },
  ) => void;
  onSortOptionChange?: (
    sortOption: UseConnectorsProps["sortOption"] | null,
  ) => void;
};

const defaultSortFn = (a: ConnectorOption, b: ConnectorOption) => {
  return a.label.localeCompare(b.label);
};

const popularSortFn =
  (abilityFilter: Ability | undefined | null) =>
  (a: ConnectorOption, b: ConnectorOption) => {
    const getTags = (option: ConnectorOption) => {
      const dataSourceTags = option.metadata?.eltSource?.tags ?? [];
      const activateDestinationTags =
        option.metadata?.activateDestination?.tags ?? [];
      if (!abilityFilter) {
        return dataSourceTags.concat(activateDestinationTags);
      }
      if (abilityFilter === "EltSourceConnection") {
        return dataSourceTags;
      }
      if (abilityFilter === "ReverseEltDestinationConnection") {
        return activateDestinationTags;
      }
      return [];
    };
    const aTags = getTags(a);
    const bTags = getTags(b);
    const aIsPopular = aTags.includes("Popular");
    const bIsPopular = bTags.includes("Popular");
    if (aIsPopular && bIsPopular) {
      return defaultSortFn(a, b);
    }
    if (aIsPopular || bIsPopular) {
      return aIsPopular ? -1 : 1;
    }
    return defaultSortFn(a, b);
  };

export function useConnectors(props: UseConnectorsProps) {
  const [searchText, setSearchText] = useState("");

  const [abilityFilter, setAbilityFilter] = useControllableState({
    defaultProp: null,
    prop: props.abilityFilter ?? null,
    onChange: props.onAbilityFilterChange,
  });

  const [sortOption, setSortOption] = useControllableState({
    defaultProp: null,
    prop: props.sortOption ?? null,
    onChange: props.onSortOptionChange,
  });

  const existingConnectionsByIntegrationId = useConnectionsByIntegrationId();

  const filteredOptions = useFilterConnectors(
    props.options,
    abilityFilter,
    searchText,
    (_, defaultSearchFilterFn) => {
      return (option) => {
        if (isCustomConnector(option.integration.id) && !option.isCommunity) {
          return true;
        }
        return defaultSearchFilterFn(option);
      };
    },
  );

  const onIntegrationsFilteredRef = useRef(props.onOptionsFiltered);
  useUpdateEffect(() => {
    onIntegrationsFilteredRef.current?.(filteredOptions, {
      abilityFilter: abilityFilter ?? null,
      searchText,
    });
  }, [filteredOptions, searchText, abilityFilter]);

  const sortedOptions = useMemo(() => {
    const { existingOptions, otherOptions } = filteredOptions.reduce<{
      existingOptions: typeof filteredOptions;
      otherOptions: typeof filteredOptions;
    }>(
      (acc, option) => {
        if (existingConnectionsByIntegrationId.has(option.value)) {
          acc.existingOptions.push(option);
        } else {
          acc.otherOptions.push(option);
        }
        return acc;
      },
      {
        existingOptions: [],
        otherOptions: [],
      },
    );

    const sortFn =
      sortOption === "popular" ? popularSortFn(abilityFilter) : defaultSortFn;

    return [...existingOptions.sort(sortFn), ...otherOptions.sort(sortFn)];
  }, [
    filteredOptions,
    abilityFilter,
    sortOption,
    existingConnectionsByIntegrationId,
  ]);

  return {
    options: sortedOptions,
    searchText,
    setSearchText,
    abilityFilter,
    setAbilityFilter,
    sortOption,
    setSortOption,
    existingConnectionsByIntegrationId,
  };
}
