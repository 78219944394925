import { getLocale } from "./locale";

export function formatBytes(bytes: number) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const value = bytes / Math.pow(k, i);
  const unit = sizes[i];
  const locale = getLocale();
  return `${new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
  }).format(value)} ${unit}`;
}
