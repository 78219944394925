import { noop } from "lodash";
import React from "react";

import AppearTransition from "@/components/elements/AppearTransition";
import WeldIconSmall from "@/components/icons/WeldIconSmall";
import ButtonPrimitive from "@/components/primitives/Button";
import classNames from "@/helpers/classNames";
import { XMarkIcon } from "@heroicons/react/20/solid";

type AppUpdaterHandle = {
  showUpdateAvailableToast: (onAccept: CallableFunction) => void;
};

export const appUpdaterHandleRef = React.createRef<AppUpdaterHandle>();

export const AppUpdater = React.forwardRef<AppUpdaterHandle, {}>(
  (props, ref) => {
    const [updateAvailable, setUpdateAvailable] = React.useState(false);
    const [showUpdatePrompt, setShowUpdatePrompt] = React.useState(false);

    const acceptHandlerRef = React.useRef<CallableFunction>(noop);

    React.useImperativeHandle(ref, () => ({
      showUpdateAvailableToast(onAccept) {
        acceptHandlerRef.current = onAccept;
        setUpdateAvailable(true);
        setShowUpdatePrompt(true);
      },
    }));

    const handleDismiss = () => {
      setShowUpdatePrompt(false);
    };

    if (showUpdatePrompt && updateAvailable) {
      return (
        <AppearTransition>
          <ToastPositioner>
            <AppUpdateToast
              onAccept={() => acceptHandlerRef.current()}
              onDismiss={handleDismiss}
            />
          </ToastPositioner>
        </AppearTransition>
      );
    }
    return null;
  },
);

export const ToastPositioner = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<"div">
>((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={classNames(
        "fixed bottom-2 right-2 z-50 shadow-sm",
        props.className,
      )}
    />
  );
});

type AppUpdateToastProps = {
  onAccept: () => void;
  onDismiss: () => void;
};

export function AppUpdateToast(props: AppUpdateToastProps) {
  return (
    <div className="relative">
      <div
        className={classNames(
          "flex items-center gap-4 rounded px-4 py-2",
          "text-white",
          "bg-gray-700 hover:bg-gray-700/[0.9] active:bg-gray-700/75",
          "dark:bg-gray-600 dark:hover:bg-gray-600/75 dark:active:bg-gray-500",
          "group relative",
        )}
        role="button"
        onClick={() => props.onAccept()}
        tabIndex={0}
      >
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-white dark:bg-gray-800">
          <WeldIconSmall className="h-7 w-7 text-gray-800 transition-all duration-400 ease-in-out group-hover:rotate-90 group-focus:rotate-[2880deg] group-focus:duration-1000 dark:text-gray-100" />
        </div>
        <div className="flex flex-col text-xs">
          <span className="text-sm font-medium">Update available</span>
          <span>Click here to update the WELD app!</span>
        </div>
      </div>
      <CloseButton
        onClick={() => {
          props.onDismiss();
        }}
      />
    </div>
  );
}

function CloseButton(props: React.ComponentProps<typeof ButtonPrimitive>) {
  return (
    <ButtonPrimitive
      className={classNames(
        "absolute right-1 top-1 rounded p-0.5",
        "text-white",
        // "bg-black/0 hover:bg-black/20",
        "bg-white/0 hover:bg-white/10",
      )}
      {...props}
    >
      <XMarkIcon className="h-5 w-5" />
    </ButtonPrimitive>
  );
}
