import { useAtom } from "jotai";
import { atomFamily, atomWithStorage } from "jotai/utils";
import { ComponentProps } from "react";

import { useRequestBetaConnectorAccessMutation } from "@/apollo/types";
import { PrimaryButton } from "@/components/elements/Button";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@/components/elements/Modal";
import { ConnectorOption } from "@/features/connectors";
import { useMountEffect } from "@/hooks/useMountEffect";
import { IntegrationLogoBox } from "@/integrations";
import { useMixpanel } from "@/monitoring/mixpanel";
import { useToast } from "@/providers/ToastProvider";
import { useCurrentAccount } from "@/providers/account";
import { createStorageKey } from "@/utils/storage";
import { Dialog } from "@headlessui/react";
import { CheckCircleIcon } from "@heroicons/react/24/outline";
import * as Sentry from "@sentry/react";

export function BetaConnectorAccessRequestDialog({
  option,
  ...dialogProps
}: {
  option: ConnectorOption;
} & ComponentProps<typeof Modal>) {
  const name = option.label;
  const mp = useMixpanel();

  useMountEffect(() => {
    mp.track("Beta Connector Dialog Opened", {
      connector: name,
    });
  });

  const { requested, handleRequestAccess, loading } = useRequestBetaConnector(
    option.integration.id,
  );

  return (
    <Modal {...dialogProps}>
      <ModalHeader className="flex items-center justify-start gap-4">
        <IntegrationLogoBox id={option.value} />
        <Dialog.Title className="text-lg">{name} is Coming Soon</Dialog.Title>
      </ModalHeader>
      <ModalCloseButton />
      <ModalBody className="flex flex-col gap-4">
        <p className="text-sm">
          We are working to make the {name} connector available to everyone.
          Click below to request access, and we will reach out to you when it is
          ready.
        </p>
      </ModalBody>
      <ModalFooter>
        {requested ? (
          <div className="flex items-center space-x-4 rounded border p-4 dark:border-gray-700">
            <CheckCircleIcon className="h-6 w-6 flex-none text-green-500" />
            <div className="text-xs">
              Your request to access the connector for {name} has been
              registered, we will reach out to you when it is ready.
            </div>
          </div>
        ) : (
          <PrimaryButton
            size="sm"
            onClick={() => {
              handleRequestAccess();
            }}
            className="self-start"
            isLoading={loading}
          >
            Request Access to {name} Connector
          </PrimaryButton>
        )}
      </ModalFooter>
    </Modal>
  );
}

const requstedBetaConnectorsAtom = atomFamily((accountId: string) =>
  atomWithStorage<{ [integrationId: string]: boolean }>(
    createStorageKey("requested-beta-connector-access", accountId),
    {},
  ),
);

const useRequestedBetaConnectors = () => {
  const { id } = useCurrentAccount();
  return useAtom(requstedBetaConnectorsAtom(id));
};

const useRequestBetaConnector = (integrationId: string) => {
  const toast = useToast();

  const [requested, setRequested] = useRequestedBetaConnectors();

  const [requestBetaConnectorAccess, { loading }] =
    useRequestBetaConnectorAccessMutation();

  const handleRequestBetaConnector = () => {
    if (requested[integrationId]) return;
    if (loading) return;

    requestBetaConnectorAccess({
      variables: {
        integrationId,
      },
      onCompleted() {
        toast(
          "Access requested",
          "We will reach out to you with when it is ready.",
          "success",
        );
        setRequested((prev) => ({
          ...prev,
          [integrationId]: true,
        }));
      },
      onError(e) {
        Sentry.captureException(e);
        toast("Failed to request access", "Please try again later.", "error");
      },
    });
  };
  return {
    handleRequestAccess: handleRequestBetaConnector,
    loading,
    requested: requested[integrationId] || false,
  };
};
