import { getLocale } from "./locale";

export const formatRowCount = (
  rows: number,
  options: Intl.NumberFormatOptions = {},
) => {
  const locale = getLocale();
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    ...options,
  }).format(rows);
};

export const formatRowCountCompact = (
  rows: number,
  options: Intl.NumberFormatOptions = {},
) => {
  const locale = getLocale();
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
    notation: "compact",
    compactDisplay: "short",
    ...options,
  }).format(rows);
};

export function formatCurrency(
  valueInCents: number,
  options: Intl.NumberFormatOptions = {},
) {
  const locale = getLocale();
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    ...options,
    style: "currency",
    currency: options.currency ?? "eur",
  }).format(valueInCents / 100);
}

export function formatPercent(
  value: number,
  options: Intl.NumberFormatOptions = {},
) {
  const locale = getLocale();
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    ...options,
    style: "percent",
  }).format(value);
}
