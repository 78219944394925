import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

import { getLocale } from "./locale";

dayjs.extend(duration);

export const formatDate = (
  input: dayjs.ConfigType,
  options: Intl.DateTimeFormatOptions = {},
) => {
  const date = dayjs(input).toDate();
  const locale = getLocale();
  return new Intl.DateTimeFormat(locale, options).format(date);
};

export const formatDuration = (milliseconds: number) => {
  const truncatedToSeconds = Math.floor(milliseconds / 1000) * 1000;
  const d = dayjs.duration(truncatedToSeconds, "milliseconds");

  const numYears = d.get("years");
  const numMonths = d.get("months");
  const numDays = d.get("days");
  const numHours = d.get("hours");
  const numMinutes = d.get("minutes");
  const numSeconds = d.get("seconds");
  const numMillis = d.get("milliseconds");

  const dynamicFormats = [
    !!numYears && "Y[y]",
    !!numMonths && "M[m]",
    !!numDays && "D[d]",
    !!numHours && "H[h]",
    !!numMinutes && "m[m]",
    !!numSeconds && "s[s]",
    !!numMillis && "SSS[ms]",
  ]
    .filter(Boolean)
    .join(" ");

  return d.format(dynamicFormats);
};

export function formatRelativeTime(
  targetDate: Date,
  options?: Intl.RelativeTimeFormatOptions & { baseDate?: Date },
) {
  const { baseDate = new Date(), ...relativeTimeOptions } = options ?? {};
  const diffInSeconds = (targetDate.getTime() - baseDate.getTime()) / 1000;
  const absDiff = Math.abs(diffInSeconds);

  let unit: Intl.RelativeTimeFormatUnit = "second";
  let divisor = 1;

  if (absDiff < 3600) {
    // minutes
    unit = "minute";
    divisor = 60;
  } else if (absDiff < 86400) {
    // hours
    unit = "hour";
    divisor = 3600;
  } else if (absDiff < 2592000) {
    // days (~30 days)
    unit = "day";
    divisor = 86400;
  } else if (absDiff < 31104000) {
    // months (~12 months)
    unit = "month";
    divisor = 2592000; // 30 days
  } else {
    unit = "year";
    divisor = 31104000; // 12 months
  }

  // Determine the relative amount in the appropriate unit.
  const value = Math.round(diffInSeconds / divisor);
  const rtf = new Intl.RelativeTimeFormat("en-GB", {
    numeric: "auto",
    style: "short",
    ...relativeTimeOptions,
  });
  return rtf.format(value, unit);
}
