import { ComponentProps, forwardRef } from "react";

import Center from "@/components/elements/layout/Center";
import classNames from "@/helpers/classNames";
import cn from "@/helpers/classNames";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import { useConnectorsContext } from "../ConnectorsProvider";

export const SearchFilter = forwardRef<
  HTMLInputElement,
  ComponentProps<"input">
>((props, ref) => {
  const { searchText, setSearchText } = useConnectorsContext();
  return (
    <div className={classNames("relative", props.className)}>
      <input
        type="text"
        ref={ref}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Search connectors..."
        {...props}
        className={cn(
          "rounded-xs h-10 w-full border border-gray-200 pl-10 pr-2 text-sm focus:border-blue-500 focus:outline-none focus:ring-1 focus:ring-blue-500 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-200",
          props.className,
        )}
      />
      <Center className="absolute left-0 top-0 h-full w-10">
        <MagnifyingGlassIcon className="h-4 text-gray-500" />
      </Center>
    </div>
  );
});
